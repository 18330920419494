import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import WrappedFrame from 'components/pages/Index/FormModals/FormComponents/WrappedFrame';
import { FormattedMessage } from 'react-intl';
import { getFormConfig } from 'components/pages/Index/FormModals/formConfigs';
import { globalHistory } from '@reach/router';
import _ from 'lodash';
import { colors } from 'styles';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/CloseRounded';
import { breakpoint } from 'utils/mediaQueries';
import BackgroundModale from 'images/modale/backgroundModale.svg';

const StyledModal = styled(Modal)`
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 2147483648;
`;
const ModalContainer = styled.div`
  position: absolute;
  width: 400px;
  background-color: white;
  outline: none;
  border-radius: 1rem;
  ${breakpoint.down('sm')`
    width: 80%
  `}
`;
const Modalheader = styled.div`
  background: linear-gradient(to right, ${colors.purple}, ${colors.red});
  border-radius: 1rem;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2rem;
    background-image: url(${BackgroundModale});
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
    ${breakpoint.down('md')`
      background-size: 102%;
     `}
    @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      background-size: 100% 200%;
      ${breakpoint.down('md')`
      background-size: 100% 210%;
     `}
    }
  }
`;
const StyledTypography = styled(Typography)`
  color: ${colors.white};
  text-transform: uppercase;
  padding: 3rem 0 1.5rem 0;
  > span {
    padding: 2.5rem 1rem 0 1rem;
  }
  ${breakpoint.down('sm')`
      padding-top: 3.5rem;
      font-size: ${props => `${props.theme.typography.body1.fontSize}px`}
  `}
`;
const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0.5rem;
`;
const StyledClose = styled(Close)`
  color: ${colors.white} !important;
  font-size: 2rem;
  font-weight: bold;
`;

function getModalStyle() {
  const top = 0;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
    margin: `2rem 0`,
  };
}

const AskDemoModale = ({
  lang,
  open,
  handleClose,
  type,
  values,
  useCase,
  webinar,
  dataUseCaseLinks,
}) => {
  const { formComponent, formHash, title } = getFormConfig(type);
  const { origin } = globalHistory.location;
  const clonedFormHash = _.cloneDeep(formHash);
  clonedFormHash['returnURL'] = `${origin}/${lang}/${
    clonedFormHash['returnURL']
  }`;
  return (
    <>
      <StyledModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalContainer style={getModalStyle()}>
          <Modalheader>
            <CloseButton>
              <Button onClick={handleClose}>
                <StyledClose fontSize="large" />
              </Button>
            </CloseButton>
            <StyledTypography variant="h6" id="modal-title" align="center">
              <FormattedMessage id={`forms.${title}`} values={values} />
            </StyledTypography>
          </Modalheader>
          <WrappedFrame
            lang={lang}
            formComponent={formComponent}
            formHash={clonedFormHash}
            useCase={webinar || useCase}
            dataUseCaseLinks={dataUseCaseLinks}
          />
        </ModalContainer>
      </StyledModal>
    </>
  );
};

export default AskDemoModale;
