import BlockTopNav from 'components/header/navMobile/BlockTopNav';
import { navigation, navSize } from 'config/navigation';
import BlockDropMenu from 'components/header/navMobile/BlockDropMenu';
import BlockLinkMenu from 'components/header/navMobile/BlockLinkMenu';
import BlockSocial from 'components/header/preheader/social';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import { breakpoint } from 'utils/mediaQueries';
import { FormattedMessage } from 'react-intl';

const MenuStyledList = styled.div`
  ${navSize};
  height: 100%;
  padding: 0;
`;
const WrapperList = styled(List)`
  ${breakpoint.only('xs')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;`}
`;

const Navigation = styled.div`
  overflow: auto;
`;

const SocialContent = styled(Grid)`
  padding: 1rem;
  img {
    max-height: 1.5rem;
  }
`;
const SideList = ({ locale, expanded, handle, toggle, langs }) => (
  <MenuStyledList>
    <WrapperList>
      <BlockTopNav locale={locale} toggle={toggle} langs={langs} />
      <Navigation>
        {navigation.map((data, index) => {
          if (data.sub) {
            return (
              <BlockDropMenu
                data={data}
                index={index}
                locale={locale}
                expanded={expanded}
                handle={handle}
                key={`BlockDropMenu${index}`}
              />
            );
          } else if (data.header) {
            return (
              <BlockLinkMenu
                locale={locale}
                data={data}
                key={`BlockLinkMenu${index}`}
              />
            );
          }
        })}
      </Navigation>
      <SocialContent>
        <BlockSocial color={'white'} />
      </SocialContent>
    </WrapperList>
  </MenuStyledList>
);
export default SideList;
