import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import BlockSocial from 'components/header/preheader/social';

const SocialImages = styled(Grid)`
  margin-top: 1rem;
  width: 60%;
  ${breakpoint.down('md')`
   width: 90%`}
  a {
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

const BlockSocialFooter = () => (
  <SocialImages container alignItems="center" justify="flex-start">
    <BlockSocial color={'white'} />
  </SocialImages>
);
export default BlockSocialFooter;
