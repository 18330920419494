const { purple, red, white } = require(`./colors`);
const { breakpoints } = require(`./breakpoints`);

const theme = {
  breakpoints: breakpoints,
  palette: {
    primary: {
      main: purple,
    },
    secondary: {
      main: red,
    },
    background: {
      default: white,
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily:
      '"Nunito", "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif',
    h1: {
      fontFamily:
        '"Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif',
      fontSize: 54,
      fontWeight: 'bold',
    },
    h2: {
      fontFamily:
        '"Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif',
      fontSize: 48,
      fontWeight: 'bold',
    },
    h3: {
      fontFamily:
        '"Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily:
        '"Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily:
        '"Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif',
      fontSize: 36,
      fontWeight: 'bold',
    },
    h6: {
      fontFamily:
        '"Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif',
      fontSize: 30,
      fontWeight: 'bold',
    },
    button: { fontWeight: 700 },
    subtitle2: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 24,
    },
    body2: {
      fontSize: 18,
    },
    caption: {
      fontSize: 14,
    },
  },
  shape: {
    borderRadius: 20,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': {
          fontDisplay: 'swap',
        },
      },
    },
    MuiButton: {
      root: {
        'border-radius': '3000px',
        'text-transform': 'initial',
        'box-shadow': 'none',
      },
      contained: {
        'box-shadow': 'none',
      },
    },
  },
};

module.exports.theme = theme;
