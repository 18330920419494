import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'styles';
import { breakpoint } from 'utils/mediaQueries';
import { FormattedMessage } from 'react-intl';

const ConnexionContent = styled(Typography)`
  display: flex;
  align-items: flex-end;
  ${breakpoint.down('lg')`
font-size: initial`}
  ${breakpoint.down('md')`
font-size: ${props => `${props.theme.typography.subtitle2.fontSize}
px`}; padding-top: 1rem `}
`;

const StyledLink = styled.a`
  color: ${colors.grey};
  line-height: 0.8;
  font-weight: normal;
  text-decoration: none;
  &:hover {
    color: ${colors.white};
  }
  ${breakpoint.down('md')`
  color: ${colors.white}`}
`;

const BlockConnexion = ({ signup }) => (
  <Grid container alignItems="center">
    <ConnexionContent variant="body2">
      <StyledLink
        href={
          signup
            ? 'http://www.cxinsights.io/sign-up'
            : 'http://www.cxinsights.io/login'
        }
      >
        <FormattedMessage id={signup ? 'header.signup' : 'header.connexion'} />
      </StyledLink>
    </ConnexionContent>
  </Grid>
);
export default BlockConnexion;
