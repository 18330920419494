import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import { InputStyles } from 'components/pages/Index/FormModals/FormComponents/InputsStyles';

export const StyledTextarea = styled.textarea`
  ${InputStyles};
  height: 8rem;
  resize: none;
  overflow: auto;
  font-family: 'Nunito', 'Roboto', Arial, 'Helvetica Neue', Helvetica,
    sans-serif;
  color: #592ea0;
  &:required {
    box-shadow: none;
  }
`;
