import Button from '@material-ui/core/Button';
import { colors } from 'styles';
import BlockConnexion from 'components/header/preheader/connexion';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Close from '@material-ui/icons/CloseRounded';
import BlockLang from 'components/header/preheader/lang';

const TopNavMobile = styled(Grid)`
  padding-right: 1rem;
  padding-bottom: 1rem;
`;
const StyledClose = styled(Close)`
  color: ${colors.white} !important;
  font-size: 4rem;
`;
const StyledBlockLang = styled(BlockLang)`
  justify-content: right;
`;
const TopRight = styled(Grid)`
  padding: 1rem;
`;
const BlockTopNav = ({ toggle, locale, langs }) => (
  <TopNavMobile container justify="space-between">
    <Grid>
      <Button onClick={toggle('right', false)}>
        <StyledClose fontSize="large" />
      </Button>
    </Grid>
    <TopRight>
      <StyledBlockLang langs={langs} />
      <BlockConnexion locale={locale} signup />
      <BlockConnexion locale={locale} />
    </TopRight>
  </TopNavMobile>
);
export default BlockTopNav;
