import React, { useState } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import { FormattedMessage } from 'react-intl';
import ReactGA from 'react-ga';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import {
  StyledInput,
  HiddenInput,
  RequiredFielsInfo,
  InputContainer,
  HiddenSelect,
} from 'components/pages/Index/FormModals/FormComponents/Inputs';
import { Label } from 'components/pages/Index/FormModals/FormComponents/Labels';
import { FormContainer } from 'components/pages/Index/FormModals/FormComponents/FormContainer';
import { StyledTextarea } from 'components/pages/Index/FormModals/FormComponents/Textarea';
import { SendButtons } from 'components/pages/Index/FormModals/FormComponents/SendButtons';
import { breakpoint } from 'utils/mediaQueries';

const DemoForm = props => {
  const { formName, xnQsjsdp, xmIwtLD, returnURL, sendText } = props.formHash;
  const sendToGa = e => {
    trackCustomEvent({
      category: 'Demo',
      action: 'Download',
      label: 'Prospect',
      value: 2,
    });
  };

  const StyledFormContainer = styled(FormContainer)`
    display: flex;
    height: 100%;
  `;

  const StyledFormControl = styled(FormControl)`
    width: 100%;

    & p {
      font-size: 0.75em;
    }
  `;

  const Input = styled(StyledInput)`
    width: ${({ long }) => (long ? '100%' : '20em')};
  `;

  const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    ${breakpoint.down('sm')`
    display: block;
   `};
  `;

  return (
    <StyledFormContainer id="crmWebToEntityForm">
      <form
        action="https://crm.zoho.eu/crm/WebToLeadForm"
        name={formName}
        method="POST"
        onSubmit={() => {
          props.onSubmit();
        }}
        acceptCharset="UTF-8"
        style={{ margin: 'auto' }}
      >
        <StyledFormControl>
          <HiddenSelect name="Lead Source">
            <option value="-None-">-None-</option>
            <option value="Publicit&eacute;">Publicit&eacute;</option>
            <option value="Appel&#x20;entrant">Appel entrant</option>
            <option value="R&eacute;f&eacute;rence&#x20;d&rsquo;employ&eacute;">
              R&eacute;f&eacute;rence d&rsquo;employ&eacute;
            </option>
            <option value="R&eacute;f&eacute;rence&#x20;externe">
              R&eacute;f&eacute;rence externe
            </option>
            <option value="Partenaire">Partenaire</option>
            <option value="Relations&#x20;Publiques">
              Relations Publiques
            </option>
            <option value="Salon">Salon</option>
            <option selected value="Formulaire&#x20;Web">
              Formulaire Web
            </option>
            <option value="Moteur&#x20;de&#x20;recherche">
              Moteur de recherche
            </option>
            <option value="Facebook">Facebook</option>
            <option value="Twitter">Twitter</option>
            <option value="Advertisement">Advertisement</option>
            <option value="Cold&#x20;Call">Cold Call</option>
            <option value="Employee&#x20;Referral">Employee Referral</option>
            <option value="External&#x20;Referral">External Referral</option>
            <option value="Online&#x20;Store">Online Store</option>
            <option value="Partner">Partner</option>
            <option value="Seminar&#x20;Partner">Seminar Partner</option>
            <option value="Trade&#x20;Show">Trade Show</option>
            <option value="Web&#x20;Download">Web Download</option>
          </HiddenSelect>
          <HiddenSelect name="LEADCF1">
            <option value="-None-">-None-</option>
            <option value="Contact">Contact</option>
            <option selected value="D&eacute;mo">
              D&eacute;mo
            </option>
            <option value="Use&#x20;case">Use case</option>
          </HiddenSelect>
          <HiddenSelect name="LEADCF2">
            <option value="-None-">-None-</option>
            <option selected value="Demande&#x20;de&#x20;d&eacute;mo">
              Demande de d&eacute;mo
            </option>
            <option value="Produit&#x20;&#x3a;&#x20;Plateforme">
              Produit &#x3a; Plateforme
            </option>
            <option value="Produit&#x20;&#x3a;&#x20;Intelligence&#x20;Artificielle">
              Produit &#x3a; Intelligence Artificielle
            </option>
            <option value="Informations&#x20;compl&eacute;mentaires">
              Informations compl&eacute;mentaires
            </option>
            <option value="Collaboration">Collaboration</option>
            <option value="M&eacute;dias&#x20;&amp;&#x20;&eacute;v&egrave;nements">
              M&eacute;dias &amp; &eacute;v&egrave;nements
            </option>
            <option value="Relation&#x20;investisseur">
              Relation investisseur
            </option>
            <option value="RH&#x20;&#x2f;&#x20;Emplois&#x20;&#x2f;&#x20;Recrutement">
              RH &#x2f; Emplois &#x2f; Recrutement
            </option>
            <option value="Autre">Autre</option>
          </HiddenSelect>
          <HiddenInput type="text" name="xnQsjsdp" value={xnQsjsdp} />
          <HiddenInput type="text" name="zc_gad" id="zc_gad" value="" />
          <HiddenInput type="text" name="xmIwtLD" value={xmIwtLD} />
          <HiddenInput type="text" name="actionType" value="TGVhZHM=" />
          <HiddenInput type="text" name="returnURL" value={returnURL} />
          <br />
          <HiddenInput type="text" id="ldeskuid" name="ldeskuid" />
          <HiddenInput type="text" id="LDTuvid" name="LDTuvid" />
          <FormRow>
            <div>
              <Label>
                <FormattedMessage id="forms.firstName" />
                <RequiredFielsInfo>*</RequiredFielsInfo>
              </Label>
              <InputContainer>
                <Input type="text" maxLength="40" name="First Name" />
              </InputContainer>
            </div>
            <div>
              <Label>
                <FormattedMessage id="forms.lastName" />
                <RequiredFielsInfo>*</RequiredFielsInfo>
              </Label>
              <InputContainer>
                <Input type="text" maxLength="80" name="Last Name" required />
              </InputContainer>
            </div>
          </FormRow>
          <FormRow>
            <div>
              <Label>
                <FormattedMessage id="forms.email" />
                <RequiredFielsInfo>*</RequiredFielsInfo>
              </Label>
              <InputContainer>
                <Input type="email" maxLength="100" name="Email" required />
              </InputContainer>
            </div>
            <div>
              <Label>
                <FormattedMessage id="forms.phone" />
              </Label>
              <InputContainer>
                <Input type="tel" maxLength="30" name="Phone" />
              </InputContainer>
            </div>
          </FormRow>
          <div>
            <Label>
              <FormattedMessage id="forms.company" />
              <RequiredFielsInfo>*</RequiredFielsInfo>
            </Label>
            <InputContainer>
              <Input long type="text" maxLength="100" name="Company" required />
            </InputContainer>
          </div>
          <div>
            <Label>
              <FormattedMessage id="forms.message" />
            </Label>
            <InputContainer>
              <StyledTextarea name="LEADCF4" maxLength="2000" />
            </InputContainer>
          </div>

          <SendButtons
            isLoading={props.isLoading}
            sendText={sendText}
            onClick={sendToGa}
            plainBackground
          />
        </StyledFormControl>
      </form>
    </StyledFormContainer>
  );
};

export default DemoForm;
