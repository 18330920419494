import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors, textBold } from 'styles';
import { breakpoint } from 'utils/mediaQueries';
import { navigation } from 'config/navigation';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';

const FooterLink = styled(Grid)`
  font-size: ${props => `${props.theme.typography.body2.fontSize}
px`};
  font-family: ${props => `${props.theme.typography.fontFamily}
`};
  padding: 0 3rem;
  border-left: 0.01rem rgba(255, 255, 255, 0.5) solid;
  border-right: 0.01rem rgba(255, 255, 255, 0.5) solid;
  ${breakpoint.down('md')`
    display: none;`}
  ${textBold}
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 0.2rem 0;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
`;
const ExternalLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
`;
const BlockLinks = locale => (
  <FooterLink container justify="center" alignItems="center">
    <ul>
      <li>
        {navigation.map((data, index) => {
          if (data.footer) {
            if (data.sub) {
              return data.children.map((child, index) => {
                if (child.footer) {
                  if (child.external) {
                    return (
                      <ExternalLink
                        key={`LinksFooter${index}`}
                        href={child.link}
                      >
                        <ul>
                          <li>
                            <FormattedMessage id={child.label} />
                          </li>
                        </ul>
                      </ExternalLink>
                    );
                  }
                  return (
                    <StyledLink
                      key={`LinksFooter${index}`}
                      to={`/${locale.locale}/${child.link}`}
                    >
                      <ul>
                        <li>
                          <FormattedMessage id={child.label} />
                        </li>
                      </ul>
                    </StyledLink>
                  );
                }
              });
            } else {
              return (
                <StyledLink
                  key={`LinksFooter${index}`}
                  to={`/${locale.locale}/${data.link}`}
                >
                  <ul>
                    <li>
                      <FormattedMessage id={data.label} />
                    </li>
                  </ul>
                </StyledLink>
              );
            }
          }
        })}
      </li>
    </ul>
  </FooterLink>
);
export default BlockLinks;
