import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { theme } from 'styles/theme';
import { colors, textBold } from 'styles';

export const Label = styled(Typography)`
  flex-wrap: nowrap;
  text-align: left;
  width: 100%;
  margin: 0.5rem 0.5rem;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.caption.fontSize}px;
  text-transform: uppercase;
  ${textBold};
  color: ${colors.purple};
`;
