import { Box } from 'components/basics';
import { colors } from 'styles';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
// import BlockSlogan from './slogan';
import BlockContact from './contact';
// import BlockSocial from './social';
import BlockConnexion from './connexion';
import BlockLang from './lang';

const Preheader = styled(Grid)`
  background-color: ${colors.shades.black.light};
  color: ${colors.white};
  position: relative;
  z-index: 1;
  padding: 0 4rem;
  ${breakpoint.down('lg')`padding: 0 0.5rem`}
  ${breakpoint.down('md')`padding: 0; `}
`;
const BoxSlogan = styled(Box)`
  ${breakpoint.down('lg')`
  width: 42%`}
  ${breakpoint.down('md')`
  display:none`}
`;
const BoxContact = styled(Box)`
  ${breakpoint.down('md')`
  width: 100%; justify-content: center; margin: 0`}
`;
const BoxConnexion = styled(Box)`
  ${breakpoint.down('md')`
  display: none`}
`;
const BoxLang = styled(Box)`
  ${breakpoint.down('md')`
  display:none`}
`;

const BoxSocial = styled(Box)`
  a {
    svg {
      ${breakpoint.down('lg')`
      width: 20px !important;
      height: 20px !important;
      `}
    }
  }
  ${breakpoint.down('md')`
  display: none`}
`;

const BlockPreheader = ({ locale, langs, pagesList }) => (
  <Preheader container direction="row" justify="space-between" id="preheader">
    <BoxSlogan width="42%" p={1}>
      {/* <BlockSlogan /> */}
    </BoxSlogan>
    <BoxSocial width={1 / 10} p={1} display="flex">
      {/* <BlockSocial color={'white'} sizeBreak={18} /> */}
    </BoxSocial>
    <BoxContact p={1} mr={1}>
      <BlockContact locale={locale} color={'white'} />
    </BoxContact>
    <BoxConnexion width={1 / 5} ml={5} display="flex">
      <BlockConnexion signup />
      <BlockConnexion />
    </BoxConnexion>
    <BoxLang p={1} display="flex">
      <BlockLang langs={langs} pagesList={pagesList} />
    </BoxLang>
  </Preheader>
);
export default BlockPreheader;
