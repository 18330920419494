import { Box } from 'components/basics';
import { colors } from 'styles';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import { navigation } from 'config/navigation';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';

const SubFooter = styled(Grid)`
  background-color: ${colors.shades.black.light};
  color: ${colors.white};
`;
const BoxLegal = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  ${breakpoint.down('md')`
    padding: 0.5rem 0`}
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => `${props.theme.typography.caption.fontSize}px`};
  color: ${colors.white};
  padding: 0 0.5rem;
  ${breakpoint.down('xs')`

      padding:0 0.2rem;`}
  ${breakpoint.down('sm')`
      font-size: 10px;
`};
`;
const SubFooterBlock = ({ locale }) => (
  <SubFooter container>
    <BoxLegal width="100%" p={1}>
      {navigation.map((data, index) => {
        if (data.subFooter) {
          return (
            <Grid key={`LinksSubFooter${index}`}>
              <StyledLink to={`/${locale}/${data.link}`}>
                <FormattedMessage id={data.label} />
              </StyledLink>
            </Grid>
          );
        }
      })}
    </BoxLegal>
  </SubFooter>
);
export default SubFooterBlock;
