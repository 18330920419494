import Logo from 'images/indexPage/footer/logo.png';
import BlockSocialFooter from 'components/footer/BlockSocialFooter';
import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { colors, textBold } from 'styles';
import { breakpoint } from 'utils/mediaQueries';
import { CompanyInformations } from 'config/companyInformations';
import ContactPhotoPng from './../footer/photo_seb_2.png';
import { FormattedMessage } from 'react-intl';
import IconBlock from 'src/components/basics/Icons.js';

const Contact = styled(Grid)`
  color: ${colors.white};
  padding: 0 3rem;
  ${breakpoint.down('lg')`
    padding: 0 2rem`};
  img {
    max-width: 130px;
    ${breakpoint.down('md')`
     max-width: 230px`}
  }
  ${breakpoint.down('md')`
  width: 100%;padding-top: 14rem; padding-bottom: 4rem ; align-items: center; text-align:center`}
  ${breakpoint.down('sm')`
   padding-top: 10rem; padding-bottom: 4rem ;`}
`;
const StyledTypography = styled(Typography)`
  color: ${colors.white};
  ${textBold};
`;
const StyledSubtitle = styled(Typography)`
  color: ${colors.white};
`;
const ContactInfos = styled.div`
  margin-top: 1.5rem;
`;
const ContactDetails = styled.div`
  font-size: 14px;
  font-family: 'Nunito', 'Roboto', Arial, 'Helvetica Neue', Helvetica,
    sans-serif;
  font-weight: 400;
  margin-left: 0.5rem;
`;
const ContactAdditionalDetails = styled.div`
  font-size: 11px;
  font-weight: 200;
  line-height: 2;
  color: rgb(183 183 183);
`;
const ContactIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  svg path {
    fill: currentColor;
  }
`;
const EmphasedCaption = styled.span`
  font-style: italic;
`;
const ContactImg = styled.img`
  width: 50px;
  height: 50px;
`;
const StyledLink = styled.a`
  color: ${colors.white};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;
const BlockContact = () => (
  <Contact container justify="center" direction="column">
    <StyledTypography variant="body2">
      <FormattedMessage id="footer.bookYourMeeting" />
    </StyledTypography>
    <StyledSubtitle variant="caption">
      <FormattedMessage id="footer.discover" />{' '}
      <EmphasedCaption>
        <FormattedMessage id="footer.free" />
      </EmphasedCaption>
    </StyledSubtitle>
    <ContactInfos>
      <StyledLink href={'mailto:' + CompanyInformations.mailMarketing}>
        <ContactIcon>
          <ContactImg src={ContactPhotoPng} />
        </ContactIcon>
        <ContactDetails>
          {CompanyInformations.mailMarketing}
          <ContactAdditionalDetails>
            <FormattedMessage id="footer.replyDelay" />
          </ContactAdditionalDetails>
        </ContactDetails>
      </StyledLink>
    </ContactInfos>
    <ContactInfos>
      <StyledLink href={'tel:' + CompanyInformations.telLink}>
        <ContactIcon>
          <IconBlock icon="phoneNoSound" widthIcon={30} heightIcon={30} />
        </ContactIcon>
        <ContactDetails>
          {CompanyInformations.telephone}
          <ContactAdditionalDetails>
            <FormattedMessage id="footer.openingTime" />
          </ContactAdditionalDetails>
        </ContactDetails>
      </StyledLink>
    </ContactInfos>

    <BlockSocialFooter />
  </Contact>
);
export default BlockContact;
