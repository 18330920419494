import React from 'react';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors, textBold } from 'styles';
import { breakpoint } from 'utils/mediaQueries';

const SubNav = styled(Grid)`
  z-index: 1;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 0.25rem ${colors.grey};
  border-radius: 0.5rem;
  margin-top: 1rem;
  margin-left: ${props => (props.nbchild <= 2 ? 0 : '-20rem')};
  a {
    text-decoration: none;
    padding: 2rem 0;
    color: ${props => `${props.theme.palette.primary.main}
`};
  }
`;
const StyledLink = styled(Link)`
  background-color: ${({ colorback }) =>
    colorback ? `${colors.purple}` : `${colors.white}`};
`;
const ExternalLink = styled.a`
  background-color: ${({ colorback }) =>
    colorback ? `${colors.purple}` : `${colors.white}`};
`;
const StyledMenu = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
`;
const LiMenu = styled.li`
  border-right: ${({ separator }) =>
    separator ? `0.02rem solid ${colors.purple}` : 'none'};
  color: ${({ colorback }) =>
    colorback ? `${colors.white}` : `${colors.purple}`};
  ${textBold};
  font-size: ${props => `
${props.theme.typography.subtitle2.fontSize}
px`};
  width: 250px;
  height: 100%;
  padding: 0 2rem;
  ${breakpoint.down('lg')`
    padding: 0 1rem;font-size:initial`}
`;

class Submenu extends React.Component {
  render() {
    return (
      <SubNav nbchild={this.props.nbchild}>
        <StyledMenu>
          {this.props.data.map((submenu, index) => {
            if (submenu.external) {
              return (
                <ExternalLink
                  href={submenu.link}
                  target={'__blank'}
                  colorback={submenu.color}
                  key={`StyledLink${index}`}
                >
                  <LiMenu
                    separator={this.props.data.length > 1 && submenu.separator}
                    colorback={submenu.color}
                  >
                    <FormattedMessage id={submenu.label} />
                    {submenu.content}
                  </LiMenu>
                </ExternalLink>
              );
            }
            return (
              <StyledLink
                to={`/${this.props.lang}/${submenu.link}`}
                colorback={submenu.color}
                key={`StyledLink${index}`}
              >
                <LiMenu
                  separator={this.props.data.length > 1 && submenu.separator}
                  colorback={submenu.color}
                >
                  <FormattedMessage id={submenu.label} />
                  {submenu.content}
                </LiMenu>
              </StyledLink>
            );
          })}
        </StyledMenu>
      </SubNav>
    );
  }
}
export default Submenu;
