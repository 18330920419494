import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';
import { theme } from 'styles/theme';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { breakpoint } from 'utils/mediaQueries';

const SelectContainer = styled.div`
  z-index: 1;
  position: relative;
  font-family: ${theme.typography.fontFamily};
  .select-box--arrow-down {
    background: linear-gradient(
        45deg,
        transparent 50%,
        ${colors.shades.purple.light} 50%
      ),
      linear-gradient(
        135deg,
        ${colors.shades.purple.light} 50%,
        transparent 50%
      ),
      linear-gradient(
        to right,
        ${colors.shades.purple.light},
        ${colors.shades.purple.light}
      );
    background-position: calc(100% - 20px) calc(1em + 0px),
      calc(100% - 10px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
    background-size: 10px 10px, 10px 10px, 1px 1.5em;
    background-repeat: no-repeat;
  }
  .selected {
    background-color: ${colors.shades.purple.light};
    color: white;
  }
`;
const Container = styled.div`
  z-index: 1;
  cursor: pointer;
  border: 1px solid #592ea0;
  background: #fff;
  padding: 10px 3rem 10px 1.3rem;
  outline: none;
  border-radius: 20px;
  font-size: 16px;
  color: #7a57b3;
`;
const Options = styled(Container)`
  left: 0;
  top: -0.2rem;
  position: absolute;
  width: 99.5%;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  overflow-y: scroll;
  ${breakpoint.down('xs')`
    width: 99%
    font-size: ${props => `${props.theme.typography.caption.fontSize}px`}
  `}
`;
const ItemContainer = styled.div`
  width: 100%;
  cursor: pointer;
  height: 100%;
`;
const Item = styled.div`
  padding: 10px 1.3rem;
  background-color: ${props => props.selected};
  &:first-child {
    border-radius: 20px 20px 0 0;
  }
  &:last-child {
    border-radius: 0 0 20px 20px;
  }
  &:hover {
    color: ${colors.shades.purple.light};
    background-color: ${colors.shades.grey.light};
  }
`;
class SelectBox extends React.Component {
  state = {
    ...this.props,
    items: this.props.items || [],
    showItems: false,
    selectedItem: this.props.items && _.find(this.props.items, 'selected'),
  };
  componentDidMount() {
    this.props.getoption(this.state.selectedItem);
  }

  dropDown = () => {
    this.setState(prevState => ({
      showItems: !prevState.showItems,
    }));
  };
  selectItem = item => {
    this.setState({
      selectedItem: item,
      showItems: true,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedItem !== this.state.selectedItem) {
      this.props.getoption(this.state.selectedItem);
    }
  }

  render() {
    document.addEventListener('click', evt => {
      const flyoutElement = document.getElementById('options');
      let targetElement = evt.target;
      do {
        if (targetElement === flyoutElement) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.setState({
        showItems: false,
      });
    });
    return (
      <SelectContainer>
        <Container className={'select-box--arrow-down'} onClick={this.dropDown}>
          <Typography variant="caption">
            <FormattedMessage id={this.state.selectedItem.label} />
          </Typography>

          <Options
            id="options"
            style={{ display: this.state.showItems ? 'flex' : 'none' }}
          >
            <ItemContainer>
              {this.state.items.map((item, index) => (
                <Item
                  className={this.state.selectedItem === item ? 'selected' : ''}
                  key={index}
                  onClick={() => this.selectItem(item)}
                >
                  <FormattedMessage id={item.label} />
                </Item>
              ))}
            </ItemContainer>
          </Options>
        </Container>
      </SelectContainer>
    );
  }
}
export default SelectBox;
