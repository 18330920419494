import React from 'react';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import styled, { StyleSheetManager } from 'styled-components';

const StyledFrame = styled(Frame)`
  width: 100%;
  border: none;
  padding: 0 1.5rem 1rem 1.5rem;
  z-index: -1;
  overflow: hidden;
`;

class WrappedFrame extends React.Component {
  componentWillUnmount() {
    this.container &&
      this.container.contentWindow.removeEventListener(
        'resize',
        this.handleResize
      );
  }

  iframeReady() {
    let iframe = document.getElementById('iframeTarget');
    const tolerance = 40;
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if (iframeWin.document.body) {
      iframe.height = iframeWin.document.body.clientHeight + tolerance;
    }
  }
  render() {
    const { formComponent, formHash, dataUseCaseLinks } = this.props;
    return (
      <>
        <StyledFrame
          id="iframeTarget"
          contentDidMount={this.iframeReady}
          contentDidUpdate={this.iframeReady}
        >
          <FrameContextConsumer>
            {frameContext => (
              <StyleSheetManager target={frameContext.document.head}>
                <React.Fragment>
                  {React.cloneElement(
                    formComponent,
                    { formHash: formHash, dataUseCaseLinks: dataUseCaseLinks },
                    this.props.lang,
                    this.props.useCase
                  )}
                </React.Fragment>
              </StyleSheetManager>
            )}
          </FrameContextConsumer>
        </StyledFrame>
      </>
    );
  }
}

export default WrappedFrame;
