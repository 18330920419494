import { navigation } from 'config/navigation';
import { FormattedMessage } from 'react-intl';
import Submenu from 'components/header/subMenu';
import { Button } from 'components/basics';
import { Link, navigate } from 'gatsby';
import { colors, textBold } from 'styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { FORM_TYPES } from 'components/pages/Index/FormModals/formConfigs';
import FormModal from 'components/pages/Index/FormModals/FormModal';

const ListNav = styled(Grid)`
  position: relative;
  width: 70%;
`;
const NavContent = styled(Grid)`
  padding: 1rem 0;
  font-size: ${props => `
${props.theme.typography.body2.fontSize}px`};
  font-family: ${props => `${props.theme.typography.fontFamily}`};
  color: ${props =>
    props.isHeaderShrunk
      ? colors.white
      : `${props.theme.palette.primary.main}`};
  ${textBold};
  cursor: pointer;
`;
const LinkNav = styled(Link)`
  text-decoration: none;
  color: ${props =>
    props.isHeaderShrunk
      ? colors.white
      : `${props.theme.palette.primary.main}`};
`;
const LinkExternal = styled.a`
  text-decoration: none;
  color: ${props => `${props.theme.palette.primary.main}
`};
`;
const BlockListNav = ({
  leave,
  hover,
  locale,
  subMenu,
  location: { search },
  isHeaderShrunk,
}) => {
  const [open, setOpen] = useState(search === '?demo_request');
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ListNav container justify="space-around" alignItems="center">
      {navigation.map((message, index) => {
        const nbchild = message.children;
        if (message.sub) {
          return message.link ? (
            <NavContent
              onMouseLeave={leave}
              onMouseEnter={() => hover(message.sub)}
              key={`NavContent${index}`}
              className="navContent"
              isHeaderShrunk={isHeaderShrunk}
            >
              <LinkNav
                to={`/${locale}/${message.link}`}
                className="linkNav"
                isHeaderShrunk={isHeaderShrunk}
              >
                <FormattedMessage id={message.label} />
                {subMenu === message.sub && (
                  <Submenu
                    data={message.children}
                    lang={locale}
                    nbchild={nbchild.length}
                  />
                )}
              </LinkNav>
            </NavContent>
          ) : (
            <NavContent
              onMouseLeave={leave}
              onMouseEnter={() => hover(message.sub)}
              key={`NavContent${index}`}
              className="navContent"
              isHeaderShrunk={isHeaderShrunk}
            >
              <FormattedMessage id={message.label} />
              {subMenu === message.sub && (
                <Submenu
                  data={message.children}
                  lang={locale}
                  nbchild={nbchild.length}
                />
              )}
            </NavContent>
          );
        } else if (message.link != null && message.header) {
          return !message.external ? (
            <NavContent
              variant="body1"
              key={`NavContent${index}`}
              className="navContent"
              isHeaderShrunk={isHeaderShrunk}
            >
              <LinkNav
                to={`/${locale}/${message.link}`}
                className="linkNav"
                isHeaderShrunk={isHeaderShrunk}
              >
                <FormattedMessage id={message.label} />
              </LinkNav>
            </NavContent>
          ) : (
            <NavContent
              variant="body1"
              key={`NavContent${index}`}
              className="navContent"
              isHeaderShrunk={isHeaderShrunk}
            >
              <LinkExternal rel="noopener" target="_blank" href={message.link}>
                <FormattedMessage id={message.label} />
              </LinkExternal>
            </NavContent>
          );
        }
      })}
      <Button
        onClick={() => navigate(`/${locale}/demoRequest`)}
        variant={'contained'}
        color="primary"
        customTextColor={isHeaderShrunk ? colors.purple : colors.white}
        customBackgroundColor={isHeaderShrunk ? colors.white : colors.purple}
        customHoverBackgroundColor={
          isHeaderShrunk ? colors.shades.grey.light : colors.shades.purple.dark
        }
        px={5}
      >
        <FormattedMessage id="button.testQemotion.navText" />
      </Button>
    </ListNav>
  );
};
export default BlockListNav;
