import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';

export const navSize = `width: 270px`;
const StyledList = styled.ul`
  flex-direction: column;
  list-style: initial;
  font-size: ${props => `
${props.theme.typography.caption.fontSize}

px`};
  font-weight: normal;
  padding-left: 1rem;
  padding-top: 1rem;
`;
const Content = styled.p`
  font-size: ${props => `${props.theme.typography.caption.fontSize}
px`};
  padding-top: 1rem;
  font-weight: normal;
  margin: 0;
`;
export const navigation = [
  {
    label: 'navigation.solutions',
    link: null,
    sub: 1,
    footer: true,
    external: false,
    children: [
      {
        label: 'navigation.analyseVerbatim.title',
        link: 'solutions/analyseVerbatim',
        footer: true,
        external: false,
        separator: true,
        content: (
          <StyledList>
            <li>
              <FormattedMessage id="navigation.analyseVerbatim.content.detect" />
            </li>
            <li>
              <FormattedMessage id="navigation.analyseVerbatim.content.view" />
            </li>
            <li>
              <FormattedMessage id="navigation.analyseVerbatim.content.understand" />
            </li>
            <li>
              <FormattedMessage id="navigation.analyseVerbatim.content.monitor" />
            </li>
          </StyledList>
        ),
      },
      {
        label: 'navigation.irritantsParcoursClient.title',
        link: 'solutions/irritantsParcoursClient',
        footer: true,
        separator: true,
        external: false,
        content: (
          <StyledList>
            <li>
              <FormattedMessage id="navigation.irritantsParcoursClient.content.promote" />
            </li>
            <li>
              <FormattedMessage id="navigation.irritantsParcoursClient.content.sensitize" />
            </li>
            <li>
              <FormattedMessage id="navigation.irritantsParcoursClient.content.pilot" />
            </li>
            <li>
              <FormattedMessage id="navigation.irritantsParcoursClient.content.understand" />
            </li>
            <li>
              <FormattedMessage id="navigation.irritantsParcoursClient.content.reduce" />
            </li>
          </StyledList>
        ),
      },
      {
        label: 'navigation.nps.title',
        link: 'solutions/nps',
        footer: true,
        external: false,
        content: (
          <StyledList>
            <li>
              <FormattedMessage id="navigation.nps.content.identify" />
            </li>
            <li>
              <FormattedMessage id="navigation.nps.content.increase" />
            </li>
            <li>
              <FormattedMessage id="navigation.nps.content.automate" />
            </li>
            <li>
              <FormattedMessage id="navigation.nps.content.pilot" />
            </li>
          </StyledList>
        ),
      },
    ],
  },
  {
    label: 'navigation.ressources',
    link: null,
    sub: 2,
    footer: true,
    external: false,
    children: [
      {
        label: 'navigation.casClient',
        link: 'clientCase',
        footer: true,
        external: false,
        separator: true,
        content: (
          <Content>
            <FormattedMessage id="navigation.content.client" />
          </Content>
        ),
      },
      {
        label: 'navigation.webinars',
        link: 'webinars',
        footer: true,
        external: false,
        separator: true,
        content: (
          <Content>
            <FormattedMessage id="navigation.content.webinar" />
          </Content>
        ),
      },
      {
        label: 'navigation.checklists',
        link: 'checklist',
        footer: true,
        external: false,
        separator: true,
        content: (
          <Content>
            <FormattedMessage id="navigation.content.checklists" />
          </Content>
        ),
      },
      {
        label: 'navigation.whitepapers',
        link: 'whitepaper',
        footer: true,
        external: false,
        separator: false,
        content: (
          <Content>
            <FormattedMessage id="navigation.content.whitepapers" />
          </Content>
        ),
      },
      // {
      //   label: 'navigation.documentation.title',
      //   link: 'http://api.qemotion.com/api/documentation',
      //   footer: true,
      //   external: true,
      //   content: (
      //     <Content>
      //       <FormattedMessage id="navigation.documentation.content.api" />
      //     </Content>
      //   ),
      // },
    ],
  },
  {
    label: 'navigation.pricing',
    link: 'pricing',
    sub: false,
    footer: true,
    header: true,
    external: false,
  },
  {
    label: 'navigation.aboutUs',
    link: 'aboutUs',
    sub: false,
    footer: true,
    header: true,
    external: false,
  },
  {
    label: 'navigation.blog.title',
    link: 'blog',
    sub: 3,
    footer: false,
    header: true,
    external: false,
    children: [
      {
        label: 'navigation.customerRelation.title',
        link: 'blog/customerRelation',
        footer: true,
        external: false,
        separator: true,
      },
      {
        label: 'navigation.insights.title',
        link: 'blog/insights',
        footer: true,
        external: false,
        separator: true,
      },
      {
        label: 'navigation.blogUseCases.title',
        link: 'blog/useCases',
        footer: true,
        external: false,
        separator: false,
      },
    ],
  },
  {
    label: 'footer.legalInformation',
    link: 'legalMention/legalInformation',
    sub: false,
    footer: false,
    subFooter: true,
    external: false,
  },
  {
    label: 'footer.privacyPolicy',
    link: 'legalMention/privacyPolicy',
    sub: false,
    footer: false,
    subFooter: true,
    external: false,
  },
  {
    label: 'footer.termsConditions',
    link: 'legalMention/termsConditions',
    sub: false,
    footer: false,
    subFooter: true,
    external: false,
  },
];
