import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import { InputStyles } from 'components/pages/Index/FormModals/FormComponents/InputsStyles';
import { breakpoint } from 'utils/mediaQueries';
export const StyledInput = styled(InputBase)`
  > * {
    ${InputStyles};
    pointer-events: initial;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;
export const HiddenSelect = styled.select`
  display: none;
`;

export const RequiredFielsInfo = styled.span`
  color: red;
`;

export const InputContainer = styled.div`
  width: 100%;
  ${breakpoint.down('md')`
    font-size: ${props => `${props.theme.typography.caption.fontSize}px`}
  `};
`;
