import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../utils/mediaQueries';

type YoutubeVideoEmbedProps = {
  youtubeID: string;
  youtubeTitle: string;
  width: string;
  height: string;
};

const Stylediframe = styled.iframe`
  ${breakpoint.only('xs')`width: 100%;`};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const IFrameContainer = styled.div`
  position: relative;
  padding-bottom: 56.15%;
  height: 0;
  overflow: hidden;
`;

const YTVideoContainer = styled.div<{ maxWidth: string }>`
  width: ${({ maxWidth }) => maxWidth}px;
  max-width: ${({ maxWidth }) => maxWidth}px;
  box-shadow: 6px 6px 10px hsl(206.5, 0%, 75%);
  margin: 2rem;
`;

const YoutubeVideoEmbed = ({
  youtubeID,
  youtubeTitle,
  width,
  height,
}: YoutubeVideoEmbedProps) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <>
      {loaded && (
        <YTVideoContainer maxWidth={width}>
          <IFrameContainer>
            <Stylediframe
              width={width}
              height={height}
              src=""
              srcDoc={`
              <style>
                * {
                padding: 0;
                margin: 0;
                overflow: hidden;
                }

                body, html {
                  height: 100%;
                }

                img, svg {
                  position: absolute;
                  width: 100%;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }

                svg {
                  filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                  transition: all 250ms ease-in-out;
                }

                body:hover svg {
                  filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                  transform: scale(1.2);
                }
              </style>
              <a href='https://www.youtube.com/embed/${youtubeID}?autoplay=1'>
                <img src='https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg' alt=${youtubeTitle}>
                <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'>
                  <circle cx='12' cy='12' r='10'></circle>
                  <polygon points='10 8 16 12 10 16 10 8'></polygon>
                </svg>
              </a>`}
              loading="lazy"
              data-src={`https://www.youtube.com/embed/${youtubeID}`}
              title={`${youtubeTitle}`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </IFrameContainer>
        </YTVideoContainer>
      )}
    </>
  );
};

export default YoutubeVideoEmbed;
