import styled, { css } from 'styled-components';
import { colors } from 'styles';

export const InputStyles = css`
  border-radius: 20px;
  position:relative;
  background-color: #fff;
  border: 1px solid ${colors.purple};
  color: ${colors.shades.purple.light};
  font-size: 16px;
  width: 100%;
  height: 40px;
  padding: 10px 1.3rem;
  box-sizing: border-box;
  &:focus {
    //border-radius: 4px;
    border-color: #80bdff;
    box-shadow: none;
    outline: none ! important;
  },
`;
