import { Link } from 'gatsby';
import { Typography } from '@material-ui/core';
import { colors } from 'styles';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const StyledListItem = styled(ListItem)`
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
`;
const StyledListItemText = styled(ListItemText)`
  padding: 0;
  text-decoration: none;
`;
const StyledDivider = styled(Divider)`
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
`;
const TextItem = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 0 3rem;
  margin: 12px 0;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
`;
const LinkExternal = styled.a`
  text-decoration: none;
  color: ${props => `${props.theme.palette.primary.main}
`};
`;
const BlockLinkMenu = ({ locale, data }) => (
  <StyledListItem>
    <TextItem key={data.label}>
      {!data.external ? (
        <StyledLink to={`/${locale}/${data.link}`}>
          <StyledListItemText
            primary={
              <Typography variant="body1" style={{ color: colors.white }}>
                <FormattedMessage id={data.label} />
              </Typography>
            }
          />
        </StyledLink>
      ) : (
        <LinkExternal rel="noopener" target="_blank" href={data.link}>
          <StyledListItemText
            primary={
              <Typography variant="body1" style={{ color: colors.white }}>
                <FormattedMessage id={data.label} />
              </Typography>
            }
          />
        </LinkExternal>
      )}
    </TextItem>
    <StyledDivider component="hr" light />
  </StyledListItem>
);
export default BlockLinkMenu;
