import React from 'react';
import ContactForm from 'components/pages/Index/FormModals/modalComponents/ContactForm';
import UseCaseForm from 'components/pages/Index/FormModals/modalComponents/UseCaseForm';
import WebinarForm from 'components/pages/Index/FormModals/modalComponents/WebinarForm';
import DemoForm from 'components/pages/Index/FormModals/modalComponents/DemoForm';
import ChecklistForm from 'components/pages/Index/FormModals/modalComponents/ChecklistForm';
import WhitepaperForm from 'components/pages/Index/FormModals/modalComponents/WhitepaperForm';

export const FORM_TYPES = {
  CONTACT: 'CONTACT',
  DEMO: 'DEMO',
  USE_CASE_FR: 'USE_CASE_FR',
  USE_CASE_EN: 'USE_CASE_EN',
  USE_CASE_ES: 'USE_CASE_EN',
  WEBINAR_FR: 'WEBINAR_FR',
  WEBINAR_EN: 'WEBINAR_EN',
  WEBINAR_ES: 'WEBINAR_EN',
  CHECKLIST_FR: 'CHECKLIST_FR',
  CHECKLIST_EN: 'CHECKLIST_EN',
  CHECKLIST_ES: 'CHECKLIST_EN',
  WHITEPAPER_FR: 'WHITEPAPER_FR',
  WHITEPAPER_EN: 'WHITEPAPER_EN',
  WHITEPAPER_ES: 'WHITEPAPER_EN',
  A_USE_CASE_FR: 'A_USE_CASE_FR',
  A_USE_CASE_EN: 'A_USE_CASE_EN',
  A_USE_CASE_ES: 'A_USE_CASE_EN',
};

export function getFormConfig(clientCase) {
  switch (clientCase) {
    case 'CONTACT':
      return {
        title: 'contactUs',
        formComponent: <ContactForm />,
        formHash: {
          formName: 'WebToLeads132238000002236032',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add90bb503139fb9387f06a0e7465f45316bc',
          returnURL: 'forms/confirmContact/?formSubmitted=true',
          sendText: 'sendMessage',
        },
      };
    case 'DEMO':
      return {
        title: 'requestDemo',
        formComponent: <DemoForm />,
        formHash: {
          formName: 'WebToLeads132238000002236121',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add90c62bcc93be534e50653cd8a3caad28be',
          returnURL: 'forms/confirmDemo/?formSubmitted=true',
          sendText: 'requestDemo',
        },
      };
    case 'USE_CASE_FR':
      return {
        title: 'downloadUseCase',
        formComponent: <UseCaseForm useCaseLinks={USE_CASES_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000002227159',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add905a70b1f0382f72a4c5b3637d7c4758fc',
          returnURL: 'forms/confirmUseCase/?formSubmitted=true',
          sendText: 'requestUseCase',
        },
      };
    case 'USE_CASE_EN':
      return {
        title: 'downloadUseCase',
        formComponent: <UseCaseForm useCaseLinks={USE_CASES_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000002236182',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add90a49610b2cb543a74a79d2dbd376f74ce',
          returnURL: 'forms/confirmUseCase/?formSubmitted=true',
          sendText: 'requestUseCase',
        },
      };
    case 'WEBINAR_FR':
      return {
        title: 'downloadWebinar',
        formComponent: <WebinarForm webinarLinks={WEBINAR_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000005854057',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add90d028f8332be9bc8ac0790a76bb27830e',
          returnURL: 'forms/confirmWebinar/?formSubmitted=true',
          sendText: 'requestWebinar',
        },
      };
    case 'WEBINAR_EN':
      return {
        title: 'downloadWebinar',
        formComponent: <WebinarForm webinarLinks={WEBINAR_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000005854069',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add9022aa524ee14e7a6aacfdeb0d7702cdcc',
          returnURL: 'forms/confirmWebinar/?formSubmitted=true',
          sendText: 'requestWebinar',
        },
      };
    case 'CHECKLIST_FR':
      return {
        title: 'downloadChecklist',
        formComponent: <ChecklistForm checklistLinks={CHECKLIST_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000006483029',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add90e98a67ae7d12ea9691c96277d71c1c2d',
          returnURL: 'forms/confirmChecklist/?formSubmitted=true',
          sendText: 'requestChecklist',
        },
      };
    case 'CHECKLIST_EN':
      return {
        title: 'downloadChecklist',
        formComponent: <ChecklistForm checklistLinks={CHECKLIST_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000006483042',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add9063c984ee7aff291f12f9e3766ac790bf',
          returnURL: 'forms/confirmChecklist/?formSubmitted=true',
          sendText: 'requestChecklist',
        },
      };
    case 'WHITEPAPER_FR':
      return {
        title: 'downloadWhitepaper',
        formComponent: <WhitepaperForm whitepaperLinks={WHITEPAPER_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000007658021',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add9061b85795ec632117ab01c7f94e0fb2fb',
          returnURL: 'forms/confirmWhitepaper/?formSubmitted=true',
          sendText: 'requestWhitepaper',
        },
      };
    case 'WHITEPAPER_EN':
      return {
        title: 'downloadWhitepaper',
        formComponent: <WhitepaperForm whitepaperLinks={WHITEPAPER_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000007658003',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add9064e4d8a2eafdef16c8690cee3116d8bd',
          returnURL: 'forms/confirmWhitepaper/?formSubmitted=true',
          sendText: 'requestWhitepaper',
        },
      };
    case 'A_USE_CASE_FR':
      return {
        title: 'downloadAUseCase',
        formComponent: <UseCaseForm useCaseLinks={USE_CASES_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000002227159',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add905a70b1f0382f72a4c5b3637d7c4758fc',
          returnURL: 'forms/confirmUseCase/?formSubmitted=true',
          sendText: 'requestUseCase',
        },
      };
    case 'A_USE_CASE_EN':
      return {
        title: 'downloadAUseCase',
        formComponent: <UseCaseForm useCaseLinks={USE_CASES_LINKS} />,
        formHash: {
          formName: 'WebToLeads132238000002236182',
          xnQsjsdp:
            'dbb598ef64d03c34fa5eae2552d4ad0daf12fe972d2864e495275cda122c2f62',
          xmIwtLD:
            '22cc6fbd4313c8b43a5b078ebc5add90a49610b2cb543a74a79d2dbd376f74ce',
          returnURL: 'forms/confirmUseCase/?formSubmitted=true',
          sendText: 'requestUseCase',
        },
      };
  }
}

const CHECKLIST_LINKS = {
  en: {
    SAT_survey_analysis: 'SAT-Survey-analysis-checklist.xlsx',
    HR_survey_analysis: 'HR_Survey-analysis-checklist.xlsx',
  },
  fr: {
    analyse_enquete_SAT: 'SAT-CheckList-Analyse-d-enquete.xlsx',
    analyse_enquete_RH: 'RH-Checklist-analyse-Enquete.xlsx',
  },
  es: {
    SAT_survey_analysis: 'SAT-Survey-analysis-checklist.xlsx',
    HR_survey_analysis: 'HR_Survey-analysis-checklist.xlsx',
  },
};

const WEBINAR_LINKS = {
  en: {
    plug_and_play_bnp_paris_qemotion_pitch_2020: '3nAdYpVbufQ',
    qemotion_at_itb_berlin_convention: 'h-VxGb4BTBE',
  },
  fr: {
    emotions_x_analyse_semantique_pour_optimiser_son_parcours_client:
      'TI2SwWSFCLI',
    gagnez_15_pts_de_nps_grace_a_l_analyse_des_emotions_clients: 'MKufOifiCp0',
    assurance_x_mutuelle_optimiser_son_parcours_client_grace_aux_émotions_et_l_analyse_semantique:
      't8v6cL5Gbm8',
    banque_x_assurance_optimiser_son_parcours_client_grace_aux_émotions_et_l_analyse_semantique:
      'ft7Rf6IUlbI',
    l_emotion_pour_booster_la_sat_et_le_nps: 'xlKz2iaQlNc',
    les_emotions_pour_booster_la_sat_et_reduire_l_attrition: 'swpGKPiQqYA',
    comment_reduire_les_irritants_dans_les_parcours_clients_et_fideliser_grace_aux_emotions:
      'EDZRUGeGVGw',
  },
  es: {
    plug_and_play_bnp_paris_qemotion_pitch_2020: '3nAdYpVbufQ',
    qemotion_at_itb_berlin_convention: 'h-VxGb4BTBE',
  },
};

const WHITEPAPER_LINKS = {
  en: {
    whitepaper: 'whitepaper_en.pdf',
    whitepaper_executive_summary: 'whitepaper_executive_summary_en.pdf',
  },
  fr: {
    livre_blanc: 'livre_blanc_fr.pdf',
    condense_livre_blanc: 'condense_livre_blanc_fr.pdf',
  },
  es: {
    whitepaper: 'whitepaper_en.pdf',
    whitepaper_executive_summary: 'whitepaper_executive_summary_en.pdf',
  },
};

const USE_CASES_LINKS = {
  en: {
    agency_consulting: 'qemotion-agency_consulting-EN-Ukrpn.pdf',
    allianz: 'qemotion-allianz-EN-vLnMb.pdf',
    automotive: 'qemotion-automotive-EN-Ypg8S.pdf',
    banking: 'qemotion-banking-EN-zsKZN.pdf',
    consumer_goods: 'qemotion-consumer_goods-EN-XkflY.pdf',
    floa_bank: 'qemotion-floa_bank-EN-nX9i4.pdf',
    human_ressources: 'qemotion-human_ressources-EN-DvsCo.pdf',
    heineken_experience: 'qemotion-heineken_experience-EN-1PBns.pdf',
    insurance: 'qemotion-insurance-EN-qmI8l.pdf',
    luxury_goods: 'qemotion-luxury_goods-EN-ENt6s.pdf',
    renault: 'qemotion-renault-EN-N2CnQ.pdf',
    retail: 'qemotion-retail-EN-npHtq.pdf',
    richemont: 'qemotion-richemont-EN-Y49nI.pdf',
    tourism: 'qemotion-tourism-EN-4CTbQ.pdf',
    transportation: 'qemotion-transportation_tourism-EN-TyoNh.pdf',
    tui: 'qemotion-tui-EN-p3IWM.pdf',
    ysl_night_reboot: 'qemotion-ysl_night_reboot-EN-MRr7g.pdf',
    ysl_or_rouge: 'qemotion-ysl_or_rouge-EN-Ar7yV.pdf',
  },
  fr: {
    agency_consulting: 'qemotion-agency_consulting-FR-nTbE8.pdf',
    allianz: 'qemotion-allianz-FR-k5vBP.pdf',
    automotive: 'qemotion-automotive-FR-S2wGQ.pdf',
    banking: 'qemotion-banking-FR-0moWp.pdf',
    consumer_goods: 'qemotion-consumer_goods-FR-hOALa.pdf',
    floa_bank: 'qemotion-floa_bank-FR-3sHWi.pdf',
    heineken_experience: 'qemotion-heineken_experience-FR-TavBA.pdf',
    human_ressources: 'qemotion-human_ressources-FR-INuis.pdf',
    insurance: 'qemotion-insurance-FR-5iCB9.pdf',
    luxury_goods: 'qemotion-luxury_goods-FR-7m13F.pdf',
    renault: 'qemotion-renault-FR-1ypVG.pdf',
    retail: 'qemotion-retail-FR-78nAF.pdf',
    richemont: 'qemotion-richemont-FR-9rk3F.pdf',
    tourism: 'qemotion-tourism-FR-5yJt8.pdf',
    transportation: 'qemotion-transportation_tourism-FR-28ksu.pdf',
    tui: 'qemotion-tui-FR-TfnmW.pdf',
  },
  es: {
    agency_consulting: 'qemotion-agency_consulting-EN-Ukrpn.pdf',
    allianz: 'qemotion-allianz-EN-vLnMb.pdf',
    automotive: 'qemotion-automotive-EN-Ypg8S.pdf',
    banking: 'qemotion-banking-EN-zsKZN.pdf',
    consumer_goods: 'qemotion-consumer_goods-EN-XkflY.pdf',
    floa_bank: 'qemotion-floa_bank-EN-nX9i4.pdf',
    human_ressources: 'qemotion-human_ressources-EN-DvsCo.pdf',
    heineken_experience: 'qemotion-heineken_experience-EN-1PBns.pdf',
    insurance: 'qemotion-insurance-EN-qmI8l.pdf',
    luxury_goods: 'qemotion-luxury_goods-EN-ENt6s.pdf',
    renault: 'qemotion-renault-EN-N2CnQ.pdf',
    retail: 'qemotion-retail-EN-npHtq.pdf',
    richemont: 'qemotion-richemont-EN-Y49nI.pdf',
    tourism: 'qemotion-tourism-EN-4CTbQ.pdf',
    transportation: 'qemotion-transportation_tourism-EN-TyoNh.pdf',
    tui: 'qemotion-tui-EN-p3IWM.pdf',
    ysl_night_reboot: 'qemotion-ysl_night_reboot-EN-MRr7g.pdf',
    ysl_or_rouge: 'qemotion-ysl_or_rouge-EN-Ar7yV.pdf',
  },
};
