import { Link } from 'gatsby';
import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import logoQemotion from 'images/indexPage/header/logo.png';
import logoQemotionWeb from 'images/indexPage/header/logo.webp';
import logoQemotionWhite from 'images/indexPage/header/logo-white.png';
import logoQemotionWhiteWeb from 'images/indexPage/header/logo-white.webp';
import NavMobile from './navMobile';
import BlockPreheader from './preheader/preheader';
import BlockListNav from './BlockListNav';
import Content from 'components/basics/Content';
import { colors } from 'styles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const BlockNav = styled('div')`
  margin: 0 auto;
  ${Content};
  padding: ${({ isHeaderShrunk }) =>
    isHeaderShrunk ? '0.5rem 2rem' : '3rem 2rem'};
  transition: 0.5s;
`;
const Logo = styled(Grid)`
  width: 30%;
  img {
    max-width: 250px;
  }
`;

const StyledHeader = styled.header`
  position: sticky;
  transition: 0.5s;
  top: 0;
  margin-bottom: 1.45rem;
  background-color: ${({ isHeaderShrunk }) =>
    isHeaderShrunk ? colors.purple : 'transparent'};
`;

const TemporaryAnnouncementContainer = styled.div`
  font-size: 20px;
  font-family: 'Nunito', 'Roboto', Arial, 'Helvetica Neue', Helvetica,
    sans-serif;
  line-height: 1.5;
  color: #fff;
  background-color: #592ea0;
  text-align: center;
  overflow: hidden;
  height: 2rem;
  width: 100%;
`;

const TemporaryAnnouncementText = styled.div`
  transform: translateX(100vw);
  animation: scroll-animation 45s linear infinite;
  width: max-content;

  @keyframes scroll-animation {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(-100%);
    }
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubMenu: false,
      width: 200,
      loadingMenu: true,
      height: 0,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions() {
    const heightNav = document.getElementById('headerNav');
    this.setState({
      width: window.innerWidth,
      height: heightNav && heightNav.clientHeight,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const heightNav = document.getElementById('headerNav');
    if (heightNav && prevState.height !== this.state.height) {
      this.updateDimensions();
    }
  }

  handleHover = idSub => {
    this.setState({ showSubMenu: idSub });
  };

  handleLeave = () => {
    this.setState({ showSubMenu: false });
  };

  render() {
    const isMobile = `${breakpoints.values.md}`;
    const { temporaryEventDateFrom, temporaryEventDateTo } = this.props; // dates of temporary event in en.json, transiting via props in layout.js
    const temporaryEventDateEnd = moment(temporaryEventDateTo);
    const today = moment();
    // calculation of starting announcement date (1 month before event)
    const startingAnnouncementDate = moment(temporaryEventDateFrom).subtract(
      1,
      'months'
    );
    const possibleLocales = {
      fr: 'fr-FR',
      en: 'en-EN',
      es: 'es-ES',
    };
    const locale = possibleLocales[this.props.locale]
      ? possibleLocales[this.props.locale]
      : 'en-EN';
    const dateDisplayOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const dateFrom = new Date(temporaryEventDateFrom).toLocaleString(
      locale,
      dateDisplayOptions
    );
    const dateTo = new Date(temporaryEventDateTo).toLocaleString(
      locale,
      dateDisplayOptions
    );

    const getDateFrom = () => {
      switch (locale) {
        case 'fr-FR':
          if (
            moment(temporaryEventDateFrom).year() ===
              moment(temporaryEventDateTo).year() &&
            moment(temporaryEventDateFrom).month() ===
              moment(temporaryEventDateTo).month()
          ) {
            return moment(temporaryEventDateFrom).format('DD');
          }
          if (
            moment(temporaryEventDateFrom).year() ===
            moment(temporaryEventDateTo).year()
          ) {
            return new Date(temporaryEventDateFrom).toLocaleString(locale, {
              month: 'long',
              day: 'numeric',
            });
          }
          return new Date(temporaryEventDateFrom).toLocaleString(
            locale,
            dateDisplayOptions
          );
        case 'en-EN':
          if (
            moment(temporaryEventDateFrom).year() ===
              moment(temporaryEventDateTo).year() &&
            moment(temporaryEventDateFrom).month() ===
              moment(temporaryEventDateTo).month()
          ) {
            return new Date(temporaryEventDateFrom).toLocaleString(locale, {
              month: 'long',
              day: 'numeric',
            });
          }
          if (
            moment(temporaryEventDateFrom).year() ===
            moment(temporaryEventDateTo).year()
          ) {
            return new Date(temporaryEventDateFrom).toLocaleString(locale, {
              month: 'long',
              day: 'numeric',
            });
          }
          return new Date(temporaryEventDateFrom).toLocaleString(
            locale,
            dateDisplayOptions
          );
        case 'es-ES':
          if (
            moment(temporaryEventDateFrom).year() ===
              moment(temporaryEventDateTo).year() &&
            moment(temporaryEventDateFrom).month() ===
              moment(temporaryEventDateTo).month()
          ) {
            return new Date(temporaryEventDateFrom).toLocaleString(locale, {
              month: 'long',
              day: 'numeric',
            });
          }
          if (
            moment(temporaryEventDateFrom).year() ===
            moment(temporaryEventDateTo).year()
          ) {
            return new Date(temporaryEventDateFrom).toLocaleString(locale, {
              month: 'long',
              day: 'numeric',
            });
          }
          return new Date(temporaryEventDateFrom).toLocaleString(
            locale,
            dateDisplayOptions
          );
        default:
          if (
            moment(temporaryEventDateFrom).year() ===
              moment(temporaryEventDateTo).year() &&
            moment(temporaryEventDateFrom).month() ===
              moment(temporaryEventDateTo).month()
          ) {
            return new Date(temporaryEventDateFrom).toLocaleString(locale, {
              month: 'long',
              day: 'numeric',
            });
          }
          if (
            moment(temporaryEventDateFrom).year() ===
            moment(temporaryEventDateTo).year()
          ) {
            return new Date(temporaryEventDateFrom).toLocaleString(locale, {
              month: 'long',
              day: 'numeric',
            });
          }
          return new Date(temporaryEventDateFrom).toLocaleString(
            locale,
            dateDisplayOptions
          );
      }
    };

    const getDateTo = () => {
      if (locale === 'en-EN') {
        if (
          moment(temporaryEventDateFrom).year() ===
            moment(temporaryEventDateTo).year() &&
          moment(temporaryEventDateFrom).month() ===
            moment(temporaryEventDateTo).month()
        ) {
          return moment(temporaryEventDateTo).format('DD, yyyy');
        }
      }
      return new Date(temporaryEventDateTo).toLocaleString(
        locale,
        dateDisplayOptions
      );
    };

    const isTemporaryEvent = () => {
      // checks if temporary event has to be announced
      return today.isBetween(
        startingAnnouncementDate,
        temporaryEventDateEnd,
        'day',
        '[]'
      );
    };

    if (this.state.width > isMobile) {
      return (
        <>
          {isTemporaryEvent() && (
            <TemporaryAnnouncementContainer>
              <TemporaryAnnouncementText>
                <FormattedMessage
                  id="header.temporaryEvent.title"
                  description="announcement"
                  defaultMessage="Q°emotion will be at the Stratégie Clients event in Paris from {dateFrom} to {dateTo}"
                  values={{
                    dateFrom: getDateFrom(),
                    dateTo: getDateTo(),
                  }}
                />
                <span style={{ marginLeft: '4rem', marginRight: '4rem' }} />
                <FormattedMessage id="header.temporaryEvent.subTitle" />
              </TemporaryAnnouncementText>
            </TemporaryAnnouncementContainer>
          )}
          <StyledHeader id="headerNav" isHeaderShrunk={this.props.isHeaderShrunk}>
            <BlockPreheader
              locale={this.props.locale}
              langs={this.props.langs}
              pagesList={this.props.pagesList}
              id="preheader"
            />
            <BlockNav id="nav" isHeaderShrunk={this.props.isHeaderShrunk}>
              <Grid container alignItems="center">
                <Logo id="logo">
                  <Link to={`/${this.props.locale}`}>
                    <picture>
                      <source
                        srcSet={
                          this.props.isHeaderShrunk
                            ? logoQemotionWhiteWeb
                            : logoQemotionWeb
                        }
                        type="image/webp"
                      />
                      <source
                        srcSet={
                          this.props.isHeaderShrunk
                            ? logoQemotionWhite
                            : logoQemotion
                        }
                        type="image/jpeg"
                      />
                      <img
                        src={
                          this.props.isHeaderShrunk
                            ? logoQemotionWhite
                            : logoQemotion
                        }
                        alt="Logo"
                        loading="lazy"
                      />
                    </picture>
                  </Link>
                </Logo>
                <BlockListNav
                  leave={this.handleLeave}
                  hover={this.handleHover}
                  locale={this.props.locale}
                  subMenu={this.state.showSubMenu}
                  location={this.props.location}
                  isHeaderShrunk={this.props.isHeaderShrunk}
                />
              </Grid>
            </BlockNav>
          </StyledHeader>
        </>
      );
    } else {
      return (
        <>
          {isTemporaryEvent() && (
            <TemporaryAnnouncementContainer>
              <TemporaryAnnouncementText>
                <FormattedMessage
                  id="header.temporaryEvent.title"
                  description="announcement"
                  defaultMessage="Q°emotion will be at the Stratégie Clients event in Paris from {dateFrom} to {dateTo}"
                  values={{
                    dateFrom: getDateFrom(),
                    dateTo: getDateTo(),
                  }}
                />
                <span style={{ marginLeft: '4rem', marginRight: '4rem' }} />
                <FormattedMessage id="header.temporaryEvent.subTitle" />
              </TemporaryAnnouncementText>
            </TemporaryAnnouncementContainer>
          )}
          <NavMobile locale={this.props.locale} langs={this.props.langs} />
        </>
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
}

export default Header;
