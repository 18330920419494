import React from 'react';
import UE from 'images/indexPage/footer/ue.png';
import UEweb from 'images/indexPage/footer/ue.webp';
import GrandEst from 'images/indexPage/footer/grandEst.png';
import GrandEstWeb from 'images/indexPage/footer/grandEst.webp';
import Sapio from 'images/indexPage/footer/sapio.png';
import SapioWeb from 'images/indexPage/footer/sapio.webp';
import ParisnCo from 'images/indexPage/footer/parisnco.png';
import ParisnCoWeb from 'images/indexPage/footer/parisnco.webp';
import RGPDHome from 'images/indexPage/footer/RGPDHome.png';
import RGPDHomeWeb from 'images/indexPage/footer/RGPDHome.webp';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors } from 'styles';
import { breakpoint } from 'utils/mediaQueries';
import Content from 'components/basics/Content';
const ImagesPartners = [
  {
    src: UE,
    srcWeb: UEweb,
    alt: "Logo de l'union européenne",
  },
  {
    src: GrandEst,
    srcWeb: GrandEstWeb,
    alt: 'Logo de la région Grand Est',
  },
  {
    src: Sapio,
    srcWeb: SapioWeb,
    alt: 'Logo de SAP.io',
  },
  {
    src: ParisnCo,
    srcWeb: ParisnCoWeb,
    alt: 'Logo de Paris & Co',
  },
  {
    src: RGPDHome,
    srcWeb: RGPDHomeWeb,
    alt: 'Logo de la RGPD',
  },
];
const PartnershipContainer = styled(Grid)`
  margin: 0 auto;
  padding: 1.5rem 0;
  background: ${colors.white};
  ${breakpoint.down('md')`
  padding: 0`}
`;
const Partnership = styled(Grid)`
  padding: 0 2rem;
  ${breakpoint.down('md')`
  justify-content:center;`}
  ${Content}
  img {
    max-height: 200px;
    max-width: 160px;
    padding: 1rem;
    ${breakpoint.down('md')`
    max-width: 130px; max-height:170px;`}
  }
`;

const BlockPartnership = () => (
  <PartnershipContainer container justify="center" alignItems="center">
    <Partnership
      item
      container
      justify="space-between"
      alignItems="center"
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      {ImagesPartners.map((image, index) => {
        return (
          <picture key={`imgPartnership${index}`}>
            <source srcSet={image.srcWeb} type="image/webp" />
            <source srcSet={image.src} type="image/jpeg" />
            <img src={image.src} alt={image.alt} loading="lazy" />
          </picture>
        );
      })}
    </Partnership>
  </PartnershipContainer>
);
export default BlockPartnership;
