import { Typography } from '@material-ui/core';
import { colors } from 'styles';
import { FormattedMessage } from 'react-intl';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BlockSubMenu from './BlockSubMenu';

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: white;
`;
const StyledExpansionPanel = styled(ExpansionPanel)`
  width: 100%;
  box-shadow: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  margin: 0;
`;
const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  padding: 0 3rem;
  width: 100%;
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
`;
const StyledListItemText = styled(ListItemText)`
  padding: 0;
`;
const StyledDivider = styled(Divider)`
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
`;
const BlockDropMenu = ({ data, index, expanded, locale, handle }) => (
  <StyledListItem key={data.label}>
    <StyledListItemText
      primary={
        <div>
          <StyledExpansionPanel
            expanded={expanded === `NavPanel${index}`}
            onChange={handle(`NavPanel${index}`)}
            square
          >
            <StyledExpansionPanelSummary expandIcon={<StyledExpandMoreIcon />}>
              <Typography variant="body1" style={{ color: colors.white }}>
                <FormattedMessage id={data.label} />
              </Typography>
            </StyledExpansionPanelSummary>
            <BlockSubMenu data={data} locale={locale} />
          </StyledExpansionPanel>
          <StyledDivider component="hr" />
        </div>
      }
    />
  </StyledListItem>
);
export default BlockDropMenu;
