import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors } from 'styles';
import IconBlock from 'components/basics/Icons';
import { SOCIAL_LINK } from 'config/socialLinks';

const SocialImages = styled(Grid)`
  a {
    display: flex;
    align-content: center;
    align-items: center;
    &:nth-child(3) {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  path {
    fill: ${props => colors[props.coloricon]};
  }
`;

const BlockSocial = ({ color }) => {
  return (
    <SocialImages
      container
      alignItems="center"
      justify="space-between"
      coloricon={color}
    >
      {Object.keys(SOCIAL_LINK).map((social, index) => (
        <a
          target="_blank"
          rel="noopener"
          href={SOCIAL_LINK[social]}
          key={`SocialBlock${index}`}
          aria-label={`Check ${social}'s company`}
        >
          <IconBlock icon={social} heightIcon={24} widthIcon={24} />
        </a>
      ))}
    </SocialImages>
  );
};
export default BlockSocial;
