import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import fr from 'locales/fr.json';
import en from 'locales/en.json';
import es from 'locales/es.json';
import Header from 'src/components/header/header';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { theme } from 'src/styles/theme';
import SEO from 'components/seo';
import flatten from 'flat';
import Footer from 'src/components/footer/footer';

const messages = { fr: flatten(fr), en: flatten(en), es: flatten(es) };

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: scroll !important;
    padding-right: 0 !important;
  }
`;

const Layout = props => {
  const [CookieStatus, setCookieStatus] = useState(
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('acceptedCookies') || false
      : null
  );
  const [isHeaderShrunk, setIsHeaderShrunk] = useState(false);

  const handleScroll = () => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      setIsHeaderShrunk(true);
      document.getElementById('headerNav').style.zIndex = '9999999999';
    } else {
      setIsHeaderShrunk(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function acceptCookie() {
    window.sessionStorage.setItem('acceptedCookies', true);
    setCookieStatus(window.sessionStorage.getItem('acceptedCookies'));
  }
  const { children, data, location, seo } = props;
  const {
    availableLanguages,
    defaultLangKey,
  } = data.site.siteMetadata.languages;

  const langKey = getCurrentLangKey(
    availableLanguages,
    defaultLangKey,
    location.pathname
  );

  seo.lang = langKey;
  const url = location.pathname;
  const homeLink = `/${langKey}/`;
  const langsMenu = getLangs(
    availableLanguages,
    langKey,
    getUrlForLang(homeLink, url)
  );
  setTimeout(function() {
    let titleWidget = '';
    typeof window !== 'undefined'
      ? ((titleWidget = document.getElementById('titlediv')),
        titleWidget !== null ? (titleWidget.style.opacity = '0') : null)
      : null;
  }, 5000);
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <IntlProvider locale={langKey} messages={messages[langKey]}>
          <>
            <SEO {...seo} />
            <Header
              siteTitle={data.site.siteMetadata.title}
              locale={langKey}
              langs={langsMenu}
              location={location}
              pagesList={data.allSitePage.nodes.map(node => node.path)}
              isHeaderShrunk={isHeaderShrunk}
              temporaryEventDateFrom={en.header.temporaryEvent.from}
              temporaryEventDateTo={en.header.temporaryEvent.to}
            />
            <main>
              <style type="text/css">
                {`
          .zsiq_floatmain{
            z-index: 10 !important
        `}
              </style>
              {children}
            </main>
            <Footer locale={langKey} location={location} />
          </>
        </IntlProvider>
      </ThemeProvider>
    </>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            languages {
              availableLanguages
              defaultLangKey
            }
          }
        }
        allSitePage {
          nodes {
            path
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  seo: PropTypes.objectOf(PropTypes.string).isRequired,
};
