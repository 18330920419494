import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { theme } from 'styles/theme';
import { colors, textBold } from 'styles';
import IconBlock from 'components/basics/Icons';

const ContainButton = styled.div`
  text-align: center;
  padding-top: 2rem;
  font-size: 12px;
`;

const ButtonsInput = styled(Button)`
  font-size: 12px;
  padding: ${props => props.padding}rem;
  width: 100%;
  cursor: pointer;
  color: ${colors.white};
  background: ${({ plainBackground }) =>
    plainBackground
      ? colors.purple
      : `linear-gradient(to right, ${colors.purple}, ${colors.red})`};
  ${{ ...theme.overrides.MuiButton.root }};
  text-transform: uppercase;
  ${textBold};
  outline: none;
  border: none;
`;

const Loading = styled.div`
  padding: 0;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  animation: rotation 2s linear infinite;
  path {
    fill: ${props => colors[props.coloricon]};
  }
`;

export const SendButtons = ({
  isLoading,
  sendText,
  onClick,
  plainBackground,
}) => (
  <ContainButton>
    <ButtonsInput
      id="formsubmit"
      type="submit"
      value="Soumettre"
      color="primary"
      padding={isLoading ? 0 : 1}
      onClick={onClick}
      plainBackground={plainBackground}
    >
      {isLoading ? (
        <Loading coloricon={'white'}>
          <IconBlock icon={'loading'} heightIcon={28} widthIcon={28} />
        </Loading>
      ) : (
        <FormattedMessage id={`forms.${sendText}`} />
      )}
    </ButtonsInput>
  </ContainButton>
);
