import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { colors } from 'styles';
import { breakpoint } from 'utils/mediaQueries';

const LangBlock = styled(Grid)`
  font-weight: normal;
  color: ${colors.grey};
  line-height: 0.8;
  text-decoration: none;
  ${breakpoint.down('md')`justify-content: flex-end`};
`;
const LangContent = styled(Typography)`
  font-weight: normal;
  color: ${props => (props.selected ? colors.white : colors.grey)};
  line-height: 0.8;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  &:hover {
    color: ${colors.white};
  }

  ${breakpoint.down('lg')`
font-size: initial`}
  ${breakpoint.down('md')`
font-size: ${props => `${props.theme.typography.body1.fontSize}px`};`}
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  //&:last-child {
  //  padding-left: 0.5rem;
  //}
`;

const Separator = styled.span`
  padding: 0.5rem;
`;

const getIcon = langKey => {
  switch (langKey) {
    case 'en':
      return 'EN';
    case 'fr':
      return 'FR';
    case 'es':
      return 'ES';
    default:
      return null;
  }
};
const BlockLang = ({ langs, pagesList }) => {
  const links = langs.map((lang, index) => (
    <React.Fragment key={`LinkLang${index}`}>
      <StyledLink
        to={
          lang.link.includes('blog') &&
          pagesList &&
          !pagesList.includes(lang.link)
            ? `/${lang.langKey}/blog`
            : lang.link
        }
      >
        <LangContent selected={lang.selected}>
          {getIcon(lang.langKey)}
        </LangContent>
      </StyledLink>
      {index !== langs.length - 1 && <Separator>|</Separator>}
    </React.Fragment>
  ));
  return (
    <LangBlock container alignItems="center" justify="space-between">
      {links}
    </LangBlock>
  );
};
export default BlockLang;
