import React from 'react';
import { colors } from 'styles';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Content from 'components/basics/Content';
import BackgroundFooter from 'images/indexPage/footer/backgroundFooter.svg';
import { breakpoint } from 'utils/mediaQueries';
import BlockPartnership from 'components/footer/BlockPartnership';
import BlockLinks from 'components/footer/BlockLinks';
import BlockContact from 'components/footer/BlockContact';
import SubFooterBlock from './subFooter/subFooter';

const StyledGrid = styled(Grid)`
  background: ${colors.purple};
  ${breakpoint.down('md')`
    background: none;`}
`;
const StyledFormattedMessage = styled(FormattedMessage)`
  padding: 0 3rem;
`;
const DescriptionGrid = styled(Grid)`
  color: ${colors.white};
  padding: 3rem 3rem;
  ${breakpoint.down('md')`
    display: none;`};
  font-size: ${props => `${props.theme.typography.body2.fontSize}
px`};
  font-family: ${props => `${props.theme.typography.fontFamily}
`};
`;
const DescriptionContent = styled(Grid)`
  padding: 0 3rem;
  ${breakpoint.down('lg')`
    padding: 0 1rem`};
`;
const LinksGrid = styled(Grid)`
  ${breakpoint.down('md')`
    display: none;`};
`;
const ContentFooter = styled(Grid)`
  margin: 0;
  padding: 3rem 0;
  background: ${colors.purple};
  ${Content};
  ${breakpoint.down('md')`
  justify-content: center; padding: 0;`}
  ${breakpoint.down('md')`
background: url('${BackgroundFooter}
');
  background-repeat: no-repeat;
  background-size: cover`}
`;
const Footer = props => {
  return (
    <footer>
      <StyledGrid container justify="center" alignItems="center">
        <BlockPartnership />
        <ContentFooter
          container
          spacing={24}
          justify="space-between"
          alignItems="center"
          direction="row"
        >
          <DescriptionGrid item md={4} lg={4}>
            <DescriptionContent>
              <StyledFormattedMessage id="footer.description" />
            </DescriptionContent>
          </DescriptionGrid>
          <LinksGrid item md={4} lg={4}>
            <BlockLinks locale={props.locale} />
          </LinksGrid>
          <Grid item xs={'auto'} sm={'auto'} md={4} lg={4}>
            <BlockContact />
          </Grid>
        </ContentFooter>
      </StyledGrid>
      <SubFooterBlock locale={props.locale} />
    </footer>
  );
};

export default Footer;
