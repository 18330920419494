import ListItemText from '@material-ui/core/ListItemText';
import { colors, textBold } from 'styles';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Typography } from '@material-ui/core';
import { StyledListItem } from './BlockLinkMenu';
const StyledList = styled.div`
  width: 270px;
  padding: 0;
`;
const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  background: ${colors.shades.purple.light};
  padding: 0;
`;
const SubText = styled(Typography)`
  padding: 0.5rem 0;
  margin-left: 4.5rem;
  ${textBold};
`;
const SubStyledListItem = styled(StyledListItem)`
  &:not(:last-child):after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
  }
`;
const StyledUl = styled.ul`
  padding: 0;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;
const ExternalLink = styled.a`
  text-decoration: none;
  width: 100%;
`;
const BlockSubMenu = ({ data, locale }) => (
  <StyledExpansionPanelDetails>
    <StyledList>
      <StyledUl>
        {data.children.map(text => {
          if (text.external) {
            return (
              <SubStyledListItem key={text.label}>
                <ExternalLink href={text.link} target={'__blank'}>
                  <ListItemText
                    primary={
                      <SubText variant="body2" style={{ color: colors.white }}>
                        <FormattedMessage id={text.label} />
                      </SubText>
                    }
                  />
                </ExternalLink>
              </SubStyledListItem>
            );
          }
          return (
            <SubStyledListItem key={text.label}>
              <StyledLink to={`/${locale}/${text.link}`}>
                <ListItemText
                  primary={
                    <SubText variant="body2" style={{ color: colors.white }}>
                      <FormattedMessage id={text.label} />
                    </SubText>
                  }
                />
              </StyledLink>
            </SubStyledListItem>
          );
        })}
      </StyledUl>
    </StyledList>
  </StyledExpansionPanelDetails>
);
export default BlockSubMenu;
