import { Button } from 'components/basics';
import { colors, textBold } from 'styles';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import FormModal from 'components/pages/Index/FormModals/FormModal';
import { FORM_TYPES } from 'components/pages/Index/FormModals/formConfigs';
import Menu from '@material-ui/core/Menu';
import IconBlock from 'components/basics/Icons';
import { CompanyInformations } from 'config/companyInformations';

const Contact = styled(Grid)`
  color: ${colors.white};
  ${breakpoint.down('lg')`
  align-items: flex-end;`}
  ${breakpoint.down('md')`
  justify-content: center;align-items: center;`}
`;
const ContactImage = styled(Grid)`
  width: auto;
  cursor: pointer;
  margin: 1rem 0 0.5rem;
  img {
    max-width: 2rem;
    ${breakpoint.down('lg')`
    max-width: 1.8rem;`}
  }
`;
const ContactButton = styled(Grid)`
  margin: 0.5rem 0;
  a {
    text-decoration: none;
  }
  span {
    ${breakpoint.down('lg')`
  font-size:12px`}
  }
`;
const DropDiv = styled(Menu)`
  z-index: 1;
  top: 2rem;
  .MuiPaper-root-203 {
    background-color: transparent;
  }
  ul {
    padding: 0;
  }
  ${breakpoint.down('lg')`
  top: 1.8rem`}
`;
const StyledMenuItem = styled.div`
  background-color: ${colors.shades.black.light};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  &:hover {
    background-color: ${colors.shades.black.light};
  }
`;
const StyledButton = styled(Button)`
  padding: 0.5rem;
  background-color: ${colors.black};
  min-width: auto;
  path {
    fill: ${props => colors[props.coloricon]};
  }
  ${breakpoint.down('lg')`
  padding: 0.4rem;`};
`;
const StyledTypography = styled(Typography)`
  color: ${colors.white};
  ${textBold};
`;
const BlockContact = ({ locale }) => {
  const [open, setOpen] = useState(false);
  const [dropDown, setDropDown] = React.useState(null);

  function handleClick(event) {
    setDropDown(event.currentTarget);
  }

  function handleCloseDrop() {
    setDropDown(null);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Contact container direction="row" alignItems="center" p={1}>
      {/* <ContactImage container alignItems="center">
        <StyledButton
          aria-controls="simple-menu"
          aria-label="Open Call menu"
          aria-haspopup="true"
          onClick={handleClick}
          coloricon={'white'}
          customHoverBackgroundColor={colors.shades.grey.normal}
        >
          <IconBlock icon={'phone'} heightIcon={16} widthIcon={16} />
        </StyledButton>
      </ContactImage> */}
      <DropDiv
        id="simple-menu"
        anchorEl={dropDown}
        keepMounted
        open={Boolean(dropDown)}
        onClose={handleCloseDrop}
      >
        <StyledMenuItem onClick={handleClose}>
          <StyledTypography variant={'caption'}>
            {CompanyInformations.telephone}
          </StyledTypography>
          <ContactButton>
            <a href={'tel:' + CompanyInformations.telLink}>
              <Button
                variant={'contained'}
                color="primary"
                customTextColor={colors.white}
                customBackgroundColor={colors.black}
                customHoverBackgroundColor={colors.shades.grey.normal}
                size="small"
                pl={2}
                pr={2}
              >
                <FormattedMessage id="button.call" />
              </Button>
            </a>
          </ContactButton>
        </StyledMenuItem>
      </DropDiv>
      <ContactButton>
        <Button
          onClick={() => handleOpen()}
          variant={'contained'}
          color="primary"
          customTextColor={colors.white}
          customBackgroundColor={colors.black}
          customHoverBackgroundColor={colors.shades.grey.normal}
          size="small"
          ml={1}
          pl={2}
          pr={2}
        >
          <FormattedMessage id="button.contact" />
        </Button>
      </ContactButton>
      <FormModal
        lang={locale}
        open={open}
        handleClose={handleClose}
        type={FORM_TYPES.CONTACT}
      />
    </Contact>
  );
};
export default BlockContact;
