import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { spacing, display } from '@material-ui/system';

const StyledButton = styled(
  ({
    customBackgroundColor,
    customTextColor,
    customHoverBackgroundColor,
    customHoverTextColor,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    margin,
    marginLeft,
    marginTop,
    marginRight,
    marginBottom,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    display,
    ...restProps
  }) => <Button {...restProps} />
)`
  background-color: ${props => props.customBackgroundColor};
  color: ${props => props.customTextColor};
  &:hover {
    background-color: ${props => props.customHoverBackgroundColor};
    color: ${props => props.customHoverTextColor};
  }
  ${spacing}
  ${display}
`;

const QButton = props => {
  return <StyledButton {...props} />;
};

QButton.propTypes = {
  customBackgroundColor: PropTypes.string,
  customTextColor: PropTypes.string,
  customHoverBackgroundColor: PropTypes.string,
  customHoverTextColor: PropTypes.string,
};

QButton.defaultProps = {
  customBackgroundColor: null,
  customTextColor: null,
  customHoverBackgroundColor: null,
  customHoverTextColor: null,
};

export default QButton;
