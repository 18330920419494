import styled from 'styled-components';
import {
  spacing,
  palette,
  display,
  borders,
  sizing
} from '@material-ui/system';

const Box = styled.div`display: inline-block;${palette}${spacing}${display}${borders}${sizing}`;

export default Box;
