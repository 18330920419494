import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { FormattedMessage } from 'react-intl';
import {
  StyledInput,
  HiddenInput,
  RequiredFielsInfo,
  InputContainer,
  HiddenSelect
} from 'components/pages/Index/FormModals/FormComponents/Inputs';
import { Label } from 'components/pages/Index/FormModals/FormComponents/Labels';
import { FormContainer } from 'components/pages/Index/FormModals/FormComponents/FormContainer';
import { StyledTextarea } from 'components/pages/Index/FormModals/FormComponents/Textarea';
import { SendButtons } from 'components/pages/Index/FormModals/FormComponents/SendButtons';
import SelectBox from '../FormComponents/SelectBox';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const ContactForm = props => {
  const [isLoading, setIsLoading] = useState(false);
  const { formName, xnQsjsdp, xmIwtLD, returnURL, sendText } = props.formHash;
  const [optionValue, setoptionValue] = useState('');
  const options = [
    { value: '-None-', label: 'forms.options.none' },
    {
      value: 'Demande de démo',
      label: 'forms.options.demo',
    },
    {
      value: 'Produit : Plateforme',
      label: 'forms.options.productPlateform',
    },
    {
      value: 'Produit : Intelligence Artificielle',
      label: 'forms.options.productAi',
    },
    {
      value: 'Informations complémentaires',
      label: 'forms.options.infos',
      selected: true,
    },
    {
      value: 'Collaboration',
      label: 'forms.options.collaboration',
    },
    {
      value: 'Médias & évènements',
      label: 'forms.options.media',
    },
    {
      value: 'Relation investisseur',
      label: 'forms.options.relation',
    },
    {
      value: 'RH / Emplois / Recrutement',
      label: 'forms.options.HR',
    },
    {
      value: 'Autre',
      label: 'forms.options.other',
    },
  ];
  function getoption(state) {
    setoptionValue(state.value);
  }
  const sendToGa = () => {
    trackCustomEvent({
      category: 'ContactUS',
      action: 'Contact',
      label: 'Prospect',
      value: 3,
    });
  };
  return (
    <FormContainer id="crmWebToEntityForm">
      <form
        action="https://crm.zoho.eu/crm/WebToLeadForm"
        name={formName}
        method="POST"
        onSubmit={() => setIsLoading(true)}
        acceptCharset="UTF-8"
      >
        <FormControl>
          <HiddenSelect name="Lead Source">
            <option value="-None-">-None-</option>
            <option value="Publicit&eacute;">Publicit&eacute;</option>
            <option value="Appel&#x20;entrant">Appel entrant</option>
            <option value="R&eacute;f&eacute;rence&#x20;d&rsquo;employ&eacute;">
              R&eacute;f&eacute;rence d&rsquo;employ&eacute;
            </option>
            <option value="R&eacute;f&eacute;rence&#x20;externe">
              R&eacute;f&eacute;rence externe
            </option>
            <option value="Partenaire">Partenaire</option>
            <option value="Relations&#x20;Publiques">
              Relations Publiques
            </option>
            <option value="Salon">Salon</option>
            <option selected value="Formulaire&#x20;Web">
              Formulaire Web
            </option>
            <option value="Moteur&#x20;de&#x20;recherche">
              Moteur de recherche
            </option>
            <option value="Facebook">Facebook</option>
            <option value="Twitter">Twitter</option>
            <option value="Advertisement">Advertisement</option>
            <option value="Cold&#x20;Call">Cold Call</option>
            <option value="Employee&#x20;Referral">Employee Referral</option>
            <option value="External&#x20;Referral">External Referral</option>
            <option value="Online&#x20;Store">Online Store</option>
            <option value="Partner">Partner</option>
            <option value="Seminar&#x20;Partner">Seminar Partner</option>
            <option value="Trade&#x20;Show">Trade Show</option>
            <option value="Web&#x20;Download">Web Download</option>
          </HiddenSelect>
          <HiddenSelect name="LEADCF1">
            <option value="-None-">-None-</option>
            <option selected value="Contact">
              Contact
            </option>
            <option value="D&eacute;mo">D&eacute;mo</option>
            <option value="Use&#x20;case">Use case</option>
          </HiddenSelect>
          <HiddenSelect name="LEADCF2">
            {options.map((option, index) => (
              <option
                key={`Options${index}`}
                value={option.value}
                selected={optionValue === option.value}
              >
                {option.label}
              </option>
            ))}
          </HiddenSelect>
          <HiddenInput type="text" name="xnQsjsdp" value={xnQsjsdp} />
          <HiddenInput type="text" name="zc_gad" id="zc_gad" value="" />
          <HiddenInput type="text" name="xmIwtLD" value={xmIwtLD} />
          <HiddenInput type="text" name="actionType" value="TGVhZHM=" />
          <HiddenInput type="text" name="returnURL" value={returnURL} />
          <br />
          <HiddenInput type="text" id="ldeskuid" name="ldeskuid" />
          <HiddenInput type="text" id="LDTuvid" name="LDTuvid" />
          <Label>
            <FormattedMessage id="forms.lastName" />
            <RequiredFielsInfo>*</RequiredFielsInfo>
          </Label>
          <InputContainer>
            <StyledInput type="text" maxLength="80" name="Last Name" required />
          </InputContainer>
          <Label>
            <FormattedMessage id="forms.firstName" />
            <RequiredFielsInfo>*</RequiredFielsInfo>
          </Label>
          <InputContainer>
            <StyledInput
              type="text"
              maxLength="40"
              name="First Name"
              required
            />
          </InputContainer>
          <Label>
            <FormattedMessage id="forms.company" />
            <RequiredFielsInfo>*</RequiredFielsInfo>
          </Label>
          <InputContainer>
            <StyledInput type="text" maxLength="100" name="Company" required />
          </InputContainer>
          <Label>
            <FormattedMessage id="forms.email" />
            <RequiredFielsInfo>*</RequiredFielsInfo>
          </Label>
          <InputContainer>
            <StyledInput type="email" maxLength="100" name="Email" required />
          </InputContainer>
          <Label>
            <FormattedMessage id="forms.phone" />
          </Label>
          <InputContainer>
            <StyledInput type="tel" maxLength="30" name="Phone" />
          </InputContainer>
          <Label>
            <FormattedMessage id="forms.subject" />
            <RequiredFielsInfo>*</RequiredFielsInfo>
          </Label>
          <SelectBox
            items={options}
            name={'optionId'}
            getoption={state => getoption(state)}
            required
          />
          <Label>
            <FormattedMessage id="forms.message" />
            <RequiredFielsInfo>*</RequiredFielsInfo>
          </Label>
          <InputContainer>
            <StyledTextarea
              name="LEADCF4"
              maxLength="32000"
              minlength="20"
              required
            />
          </InputContainer>

          <SendButtons
            isLoading={isLoading}
            sendText={sendText}
            onClick={sendToGa}
          />
        </FormControl>
      </form>
    </FormContainer>
  );
};

export default ContactForm;
