import React from 'react';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'styles';

const PurpleSwitch = withStyles(theme => ({
  root: {
    padding: 0,
    marginBottom: 16,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $bar': {
        backgroundColor: colors.purple,
        opacity: 1,
        border: 'none',
      },
    },
  },
  icon: {
    width: 24,
    height: 24,
    marginTop: 11,
    marginLeft: 5,
  },
  bar: {
    // paddingTop: 2em
    height: 26,
    width: 42,
    borderRadius: 26 / 2,
    border: `1px solid ${colors.purple}`,
    backgroundColor: colors.purple,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        icon: classes.icon,
        bar: classes.bar,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const QSwitcher = ({ checked, onChange, ...props }) => (
  <PurpleSwitch checked={checked} onChange={onChange} {...props} />
);

export default QSwitcher;
