import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';
import { Grid } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/icons/MenuRounded';
import logoQemotion from 'images/indexPage/header/logo.png';
import SideList from 'components/header/navMobile/SideList';
import { Link } from 'gatsby';
import { breakpoint } from 'utils/mediaQueries';
import logoQemotionWeb from 'images/indexPage/header/logo.webp';
import BlockPreheader from './preheader/preheader';

const MobileNav = styled(Grid)`
  padding: 1rem;
`;
const Logo = styled(Grid)`
  width: 20%;
  img {
    max-width: 240px;
    ${breakpoint.only('xxs')`
    max-width: 200px`}
  }
`;
const WrapperNav = styled.div`
  height: 100%;
`;
const StyledDrawer = styled(Drawer)`
  & > :last-child {
    background: ${colors.purple};
    color: ${colors.white};
    height: 100%;
  }
  z-index: 2247483648 !important;
`;

class NavMobile extends React.Component {
  state = {
    right: false,
    expanded: null,
  };
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { expanded } = this.state;
    return (
      <div>
        <BlockPreheader locale={this.props.locale} langs={this.props.langs} />
        <MobileNav container justify="space-between" id="headerNav">
          <Link to={`/${this.props.locale}`}>
            <Logo>
              <picture>
                <source srcSet={logoQemotionWeb} type="image/webp" />
                <source srcSet={logoQemotion} type="image/jpeg" />
                <img src={logoQemotion} alt="Logo" loading="lazy" />
              </picture>
            </Logo>
          </Link>
          <Button onClick={this.toggleDrawer('right', true)} aria-label="Menu">
            <Menu fontSize="large" color="primary" />
          </Button>
          <StyledDrawer
            anchor="right"
            open={this.state.right}
            onClose={this.toggleDrawer('right', false)}
          >
            <WrapperNav tabIndex={0} role="button" color="white">
              <SideList
                expanded={expanded}
                locale={this.props.locale}
                langs={this.props.langs}
                handle={this.handleChange}
                toggle={this.toggleDrawer}
              />
            </WrapperNav>
          </StyledDrawer>
        </MobileNav>
      </div>
    );
  }
}
export default NavMobile;
